<template>
  <v-card outlined class="mt-3">
    <v-card-title>
      <v-spacer></v-spacer>
      <v-btn class="ml-3" color="primary" @click="newMenuItem">
        Adicionar item de menu
      </v-btn>
    </v-card-title>
    <v-data-table
      :loading="loadingMenu"
      :search="search"
      :headers="columns"
      :items="menu.data"
      locale="pt"
    >
      <template v-slot:item.group="{ item }">
        <tbody>
          <tr v-for="(groupItem, index) in item.group" :key="index">
            <td>{{ groupItem.text }}</td>
          </tr>
        </tbody>
      </template>
      <template v-slot:item.icon="{ item }">
        <v-icon>{{ item.icon }}</v-icon>
      </template>
      <template v-slot:item.active="{ item }">
        <v-chip :color="item.active ? 'green' : 'red'" dark>{{
          item.active ? "Ativo" : "Inativo"
        }}</v-chip>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-icon class="mr-4" small @click="editMenu(item.id)" v-on="on">
              mdi-pencil
            </v-icon>
          </template>
          <span>Editar</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-icon small @click="deleteMenuItem(item)" v-on="on">
              mdi-delete
            </v-icon>
          </template>
          <span>Excluir</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  data() {
    return {
      loadingMenu: true,
      search: "",
      columns: [
        { text: "Nome", value: "text" },
        { text: "Rota", value: "route" },
        { text: "Grupos com acesso", value: "group" },
        { text: "Icone", value: "icon" },
        { text: "Status", value: "active" },
        { text: "", align: "end", value: "actions", sortable: false }
      ]
    };
  },
  async mounted() {
    await this.loadMenu().catch(error => {
      this.$swal("Oops...", error.message, "error");
    });
    this.loadingMenu = false;
  },
  computed: {
    ...mapState("menu", ["menu"])
  },
  methods: {
    ...mapActions("menu", ["loadMenu", "deleteMenu"]),
    newMenuItem() {
      this.$router.push({ name: "menu-new" });
    },
    editMenu(id_menu) {
      this.$router.push({ name: "menu-edit", params: { id: id_menu } });
    },
    async deleteMenuItem(menu) {
      this.loadingMenu = true;
      await this.$swal({
        icon: "warning",
        text: `Tem certeza que deseja excluir o item "${menu.text}" ?`,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#445E93",
        confirmButtonText: "Sim, remover item de menu!"
      }).then(async result => {
        if (result.value) {
          await this.deleteMenu(menu.id).catch(error => {
            this.$swal("Oops...", error.message, "error");
          });
        }
      });
      this.loadingMenu = false;
    }
  }
};
</script>
