<template>
    <v-dialog v-model="form" persistent max-width="1000px">
      <v-card>
        <v-card-title>
          <span class="title-1">  {{this.selectedEmail ? 'Editar email' : 'Cadastrar email'}} </span>
          <v-spacer></v-spacer>
          <v-btn icon @click="closeDialog()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="validForm">
              <v-col class="col-12">
                <v-text-field
                    label="E-mail"
                    outlined
                    prepend-inner-icon="mdi-email"
                    append-icon=""
                    dense
                    v-model="form.email"
                    :rules="emailRules"
                    required
                    name="config-email"
                />
              </v-col>

              <v-card elevation="0">
                <v-card-title>
                  IMAP
                </v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col class="col-sm-12 col-md-12 col-lg-6 col-xl-3">
                      <v-text-field
                          label="IMAP"
                          outlined
                          prepend-inner-icon="mdi-email-fast"
                          append-icon=""
                          dense
                          v-model="form.imap_host"
                          :rules="[v => !!v || 'O campo é obrigatório']"
                          required
                          name="config-imap-host"
                      />
                    </v-col>
                    <v-col class="col-sm-12 col-md-12 col-lg-6 col-xl-3">
                      <v-text-field
                          label="IMAP Porta"
                          outlined
                          prepend-inner-icon="mdi-email-fast"
                          append-icon=""
                          dense
                          v-model="form.imap_port"
                          :rules="[v => !!v || 'O campo é obrigatório']"
                          required
                          type="number"
                          name="config-imap-port"
                      />
                    </v-col>
                    <v-col class="col-sm-12 col-md-12 col-lg-6 col-xl-3">
                      <v-text-field
                          label="IMAP Autenticação"
                          outlined
                          prepend-inner-icon="mdi-email-fast"
                          append-icon=""
                          dense
                          v-model="form.imap_encryption"
                          :rules="[v => !!v || 'O campo é obrigatório']"
                          required
                          name="config-imap-encryption"
                      />
                    </v-col>
                    <v-col class="col-sm-12 col-md-12 col-lg-6 col-xl-3">
                      <v-text-field
                          v-model="form.imap_password"
                          :append-icon="passwordShowImap ? 'mdi-eye' : 'mdi-eye-off'"
                          :type="passwordShowImap ? 'text' : 'password'"
                          label="IMAP Senha"
                          outlined
                          dense
                          counter
                          required
                          :rules="[v => !!v || 'O campo é obrigatório']"
                          name="config-imap-password"
                      />
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>

              <v-card elevation="0">
                <v-card-title>
                  SMTP
                </v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col class="col-sm-12 col-md-12 col-lg-6 col-xl-3">
                      <v-text-field
                          label="SMTP"
                          outlined
                          prepend-inner-icon="mdi-email-newsletter"
                          append-icon=""
                          dense
                          v-model="form.mail_host"
                          :rules="[v => !!v || 'O campo é obrigatório']"
                          required
                          name="config-smtp-host"
                      />
                    </v-col>
                    <v-col class="col-sm-12 col-md-12 col-lg-6 col-xl-3">
                      <v-text-field
                          label="SMTP Porta"
                          outlined
                          prepend-inner-icon="mdi-email-newsletter"
                          append-icon=""
                          dense
                          v-model="form.mail_port"
                          :rules="[v => !!v || 'O campo é obrigatório']"
                          required
                          type="number"
                          name="config-smtp-port"
                      />
                    </v-col>
                    <v-col class="col-sm-12 col-md-12 col-lg-6 col-xl-3">
                      <v-text-field
                          label="SMTP Autenticação"
                          outlined
                          prepend-inner-icon="mdi-email-newsletter"
                          append-icon=""
                          dense
                          v-model="form.mail_encryption"
                          :rules="[v => !!v || 'O campo é obrigatório']"
                          required
                          name="config-smtp-encryption"
                      />
                    </v-col>
                    <v-col class="col-sm-12 col-md-12 col-lg-6 col-xl-3">
                      <v-text-field
                          v-model="form.mail_password"
                          :append-icon="passwordShowSMTP ? 'mdi-eye' : 'mdi-eye-off'"
                          :type="passwordShowSMTP ? 'text' : 'password'"
                          label="SMTP Senha"
                          outlined
                          dense
                          counter
                          @click:append="passwordShowSMTP = !passwordShowSMTP"
                          :rules="[v => !!v || 'O campo é obrigatório']"
                          required
                          name="config-smtp-password"
                      />
                    </v-col>
                    <v-col class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                      <v-checkbox
                        :label="'Conexão padrão do sistema'"
                        v-model="form.default_connection"
                        class="mt-0"
                        dense
                        readonly
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
              <v-card elevation="0">
                <v-card-title>
                  Assinatura
                </v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col md="12" cols="12">
                      <quill-editor
                        :content="emailSignature"
                        :options="editorOption"
                        @change="onEditorChange($event)"
                      />
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="white" class="success" text @click="testConnection()" :loading="loading || loadingButton">
                  Testar conexão
                </v-btn>
                <v-btn color="white" class="primary" text @click="saveDialog()" :loading="loading || loadingButton">
                  Salvar
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
export default {
  props: {
    titlePage: {
      type: String,
      default: ''
    },
    loading: {
      type: Boolean,
      default: false
    },
    selectedEmail: {
      default: ''
    }
  },
  components: {
    quillEditor
  },
  data() {
    return {
      emailSignature: "",
      editorOption: {
        modules: {
          toolbar: [
            [{ size: ["small", false, "large", "huge"] }],
            ["bold", "italic", "underline"],
            ["link", "image"],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
            ["clean"]
          ]
        }
      },
      form: {
        email: undefined,
        imap_host: undefined,
        imap_port: undefined,
        imap_encryption: undefined,
        imap_password: undefined,
        mail_host: undefined,
        mail_port: undefined,
        mail_encryption: undefined,
        mail_password: undefined,
        default_connection: false,
        email_signature: ""
      },
      emailRules: [
        v => !!v || 'Campo é obrigatório',
        v => /.+@.+\..+/.test(v) || 'E-mail deve ser válido',
      ],
      passwordShowImap: false,
      passwordShowSMTP: false,
      loadingButton: false,
    };
  },
  async mounted() {
    if (this.selectedEmail) {
      this.form = this.selectedEmail;
      this.emailSignature = this.selectedEmail.email_signature;
    }
  },

  methods: {
    ...mapActions("configurationCompany", ["testConnectionEmail",]),
    closeDialog() {
      this.$emit("closeDialog");
    },
    saveDialog: async function () {
      if (this.$refs.validForm.validate()) {
        this.$emit('saveDialog', this.form);
      }
    },
    onEditorChange(quill) {
      this.emailSignature = quill.html;
      this.$emit("changeMessage", {
        message: quill.html,
        index_rule: this.index
      });

      this.form.email_signature = this.emailSignature;
    },
    testConnection: async function () {
      this.loadingButton = true;
      if (this.$refs.validForm.validate()) {
        await this.testConnectionEmail(this.form).catch(error => {
          this.$swal("Oops...", error.message, "error");
        });
      }
      this.loadingButton = false;
    }
  }
};
</script>

<style scoped>
.removeOpacity:focus::before {
  opacity: 0 !important;
}
</style>
