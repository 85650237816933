var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mt-3",attrs:{"outlined":""}},[_c('v-card-title',[_c('v-spacer'),_c('v-btn',{staticClass:"ml-3",attrs:{"color":"primary"},on:{"click":_vm.newMenuItem}},[_vm._v(" Adicionar item de menu ")])],1),_c('v-data-table',{attrs:{"loading":_vm.loadingMenu,"search":_vm.search,"headers":_vm.columns,"items":_vm.menu.data,"locale":"pt"},scopedSlots:_vm._u([{key:"item.group",fn:function(ref){
var item = ref.item;
return [_c('tbody',_vm._l((item.group),function(groupItem,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(groupItem.text))])])}),0)]}},{key:"item.icon",fn:function(ref){
var item = ref.item;
return [_c('v-icon',[_vm._v(_vm._s(item.icon))])]}},{key:"item.active",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":item.active ? 'green' : 'red',"dark":""}},[_vm._v(_vm._s(item.active ? "Ativo" : "Inativo"))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-4",attrs:{"small":""},on:{"click":function($event){return _vm.editMenu(item.id)}}},on),[_vm._v(" mdi-pencil ")])]}}],null,true)},[_c('span',[_vm._v("Editar")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":""},on:{"click":function($event){return _vm.deleteMenuItem(item)}}},on),[_vm._v(" mdi-delete ")])]}}],null,true)},[_c('span',[_vm._v("Excluir")])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }