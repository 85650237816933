var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"mt-0 pt-0",attrs:{"outlined":"","loading":_vm.loading,"disabled":_vm.loading}},[_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12"}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',[_c('h3',[_c('v-icon',{staticClass:"mr-3",attrs:{"color":"primary"}},[_vm._v("mdi-format-list-checks")]),_vm._v("Emails ")],1),_c('v-spacer')],1),_c('v-card-title',[_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Pesquisar","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.columns,"items":_vm.email_config.data,"search":_vm.search,"locale":"pt","loading":_vm.loading},scopedSlots:_vm._u([{key:"item.default_connection",fn:function(ref){
var item = ref.item;
return [(item)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(item.default_connection)?_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"small":""}},on),[_vm._v(" mdi-check ")]):_vm._e(),(!item.default_connection)?_c('v-icon',_vm._g({attrs:{"small":""}},on),[_vm._v(" mdi-close ")]):_vm._e()]}}],null,true)}):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.openDialogEmailTwo(item)}}},on),[_vm._v("mdi-pencil")])]}}],null,true)},[_c('span',[_vm._v("Editar")])]):_vm._e(),(item)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":""},on:{"click":function($event){return _vm.deleteEmail(item)}}},on),[_vm._v(" mdi-delete ")])]}}],null,true)},[_c('span',[_vm._v("Excluir")])]):_vm._e()]}}])})],1)],1),_c('div',[(_vm.openDialogEmail)?_c('EmailConfigurationComponent',{attrs:{"loading":_vm.loading,"selectedEmail":_vm.selectedEmail},on:{"closeDialog":_vm.closeDialog,"saveDialog":function($event){return _vm.saveContent($event)}}}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }