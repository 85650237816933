<template>
  <v-container class="mt-0 pt-0" outlined :loading="loading" :disabled="loading">
    <v-row>
      <v-col cols="12" class="pt-0">
        <v-card outlined>
          <v-card-title>
            <h3>
              <v-icon class="mr-3" color="primary"
              >mdi-format-list-checks</v-icon
              >Emails
            </h3>
            <v-spacer></v-spacer>
          </v-card-title>
          <v-card-title>
            <v-spacer></v-spacer>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Pesquisar"
                single-line
                hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :headers="columns"
            :items="email_config.data"
            :search="search"
            locale="pt"
            :loading="loading"
          >
            <template v-slot:item.default_connection="{ item }">
              <v-tooltip left v-if="item">
                <template v-slot:activator="{ on }">
                  <v-icon v-if="item.default_connection" small class="mr-2" v-on="on"> mdi-check </v-icon>
                  <v-icon v-if="!item.default_connection" small v-on="on"> mdi-close </v-icon>
                </template>
              </v-tooltip>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-tooltip left v-if="item">
                <template v-slot:activator="{ on }">
                  <v-icon
                    small
                    class="mr-2"
                    v-on="on"
                    @click="openDialogEmailTwo(item)"
                  >mdi-pencil</v-icon
                  >
                </template>
                <span>Editar</span>
              </v-tooltip>
              <v-tooltip right v-if="item">
                <template v-slot:activator="{ on }">
                  <v-icon
                    small
                    v-on="on"
                    @click="deleteEmail(item)"
                  >
                    mdi-delete
                  </v-icon>
                </template>
                <span>Excluir</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
      <div>
        <EmailConfigurationComponent
          v-if="openDialogEmail"
          @closeDialog="closeDialog"
          @saveDialog="saveContent($event)"
          :loading="loading"
          :selectedEmail="selectedEmail"
        />
      </div>
    </v-row>
  </v-container>
</template>
<script>
import { mapActions, mapState } from "vuex";
import EmailConfigurationComponent from "@/components/emailConfig/EmailConfigurationComponent";
export default {
  components: {
    EmailConfigurationComponent
  },
  props: ["index", "index_actions"],
  data() {
    return {
      search: "",
      selectedEmail: "",
      openDialogEmail: false,
      loading: true,
      columns: [
        { text: "Email", value: "email" },
        { text: "Conexão Padrão", value: "default_connection", align: "center" },
        { text: "Data de cadastro", value: "created_at_format" },
        { text: "Data de atualização", value: "updated_at_format" },
        { text: "", align: "end", value: "actions", sortable: false }
      ],
    };
  },
  async mounted() {
    await this.loadEmailList();
  },
  computed: {
    ...mapState("configurationCompany", ["email_config", "email_config_save"])
  },
  methods: {
    ...mapActions("configurationCompany", [
      "saveEmailConfig",
      "updateEmailConfig",
      "deleteEmailConfig",
      "loadEmailConfig"
    ]),
    openDialogEmailTwo(item) {
      this.selectedEmail = item;
      this.openDialogEmail = true;
    },
    async saveContent(form) {
      this.loading = true;

      if (this.selectedEmail) {
        await this.updateEmailConfig(form).catch(error => {
          this.$swal("Oops...", error.message, "error");
        });
      }
      if (this.email_config_save.success) {
        await this.$swal({
          icon: "success",
          title: this.email_config_save.message
        });
        this.closeDialog();
      }
      await this.loadEmailList();
    },
    async deleteEmail(item) {
      await this.$swal({
        icon: "warning",
        text: `Tem certeza que deseja excluir?`,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Sim!"
      }).then(async result => {
        if (result.value) {
          await this.deleteEmailConfig(item).catch(error => {
            this.$swal("Oops...", error.message, "error");
          });
        }
      });

      await this.loadEmailList();
    },
    async loadEmailList() {
      this.loading = true;
      await this.loadEmailConfig().catch(error => {
        this.$swal("Oops...", error.message, "error");
      });
      this.loading = false;
    },
    closeDialog() {
      this.selectedEmail = "";
      this.openDialogEmail = false;
    }
  }
};
</script>
